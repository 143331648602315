var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "footer-cta" }, [
      _c("h4", [_vm._v("Looking for something else?")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "cta-button primary",
          attrs: { href: "mailto:brandhelp@onepeloton.com", target: "_blank" },
        },
        [_vm._v("REACH OUT")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "client-footer", attrs: { id: "client-footer" } },
      [
        _c(
          "div",
          [
            _c("footer-logo"),
            _vm._v(" "),
            _c("footer-links"),
            _vm._v(" "),
            _c("footer-copyright"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }