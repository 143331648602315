/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false,
    global_site_name: 'Brand Central',
    header: {
      search_label: 'Explore'
    }/*,
    showcase: {
      submit_success_message: 'A member of the Amp team will review and publish <br>your story within 1-3 business days.',
      show_page_header: true,
      search_placeholder_text: 'Search'
    }*/
  },
  icons: {
    type: 'default',
    custom: {
      ic_collections: {
        viewbox: '0 0 24 24',
        content: '<path d="M15,7H20.5L15,1.5V7M8,0H16L22,6V18A2,2 0 0,1 20,20H8C6.89,20 6,19.1 6,18V2A2,2 0 0,1 8,0M4,4V22H20V24H4A2,2 0 0,1 2,22V4H4Z"></path>'
      },
      /*ic_share: {
        viewbox: '0 0 24 24',
        content: '<path d="m18 16.08c-0.76 0-1.44 0.3-1.96 0.77l-7.13-4.15c0.05-0.23 0.09-0.46 0.09-0.7s-0.04-0.47-0.09-0.7l7.05-4.11c0.54 0.5 1.25 0.81 2.04 0.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 0.24 0.04 0.47 0.09 0.7l-7.05 4.11c-0.54-0.5-1.25-0.81-2.04-0.81-1.66 0-3 1.34-3 3s1.34 3 3 3c0.79 0 1.5-0.31 2.04-0.81l7.12 4.15c-0.05 0.21-0.08 0.43-0.08 0.66 0 1.61 1.31 2.91 2.92 2.91s2.92-1.3 2.92-2.91-1.31-2.92-2.92-2.92m0-12.08c0.55 0 1 0.45 1 1s-0.45 1-1 1-1-0.45-1-1 0.45-1 1-1m-12 9c-0.55 0-1-0.45-1-1s0.45-1 1-1 1 0.45 1 1-0.45 1-1 1m12 7c-0.55 0-1-0.45-1-1s0.45-1 1-1 1 0.45 1 1-0.45 1-1 1z" />'
      },*/
      ic_download: {
        viewbox: '0 0 24 24',
        content: '<path fill="currentColor" d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"></path>'
      },
      ic_search: {
        viewbox: '0 0 32 32',
        content: '<path fill-rule="evenodd" clip-rule="evenodd" d="m21.5 19.84 5.151 5.15c.46.46.46 1.208.029 1.64-.23.23-.547.345-.835.345-.316 0-.604-.115-.834-.346l-5.179-5.12a8.487 8.487 0 0 1-5.208 1.754 8.559 8.559 0 0 1-6.1-2.532c-3.366-3.366-3.366-8.833 0-12.2A8.56 8.56 0 0 1 14.625 6c2.216 0 4.402.834 6.1 2.532a8.577 8.577 0 0 1 .777 11.307zm-11.306-.749c1.208 1.18 2.762 1.842 4.46 1.842 1.668 0 3.28-.633 4.459-1.842 1.18-1.208 1.841-2.762 1.841-4.46 0-1.697-.633-3.28-1.841-4.459-1.209-1.18-2.762-1.841-4.46-1.841-1.697 0-3.28.661-4.46 1.841-2.445 2.474-2.445 6.474 0 8.92z" fill="currentColor"/>'
      },
      ic_tune: {
        viewbox: '0 0 24 24',
        content: '<path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" fill="currentColor"/>'
      },
      ic_menu: {
        viewbox: '0 0 24 24',
        content: '<path d="M9.75 12.375H22.5M3 5.625h19.5m-12.75 13.5H22.5" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>'
      }
    }
  }
}
